import { RequestPost } from '@/includes/types/Post.types'

import { PostType } from 'piramis-base-components/src/shared/modules/posting/types'

import store from '@/store/store'

export type UnsavedPostData = {
    post: RequestPost,
    savedAt: string
}

export function unsavedMessageExists(boardId: string): UnsavedPostData | null {
    if (store.state.AdminTemplate.user) {
        const unsavedPost = store.state.AdminTemplate.user.data[boardId]?.unsavedPostData
        return unsavedPost && unsavedPost.savedAt ? unsavedPost : null
    }
    return null
}

export function unsavedMessageType(boardId: string): PostType | null {
    if (store.state.AdminTemplate.user) {
        const unsavedPost = store.state.AdminTemplate.user.data[boardId]?.unsavedPostData

        return unsavedPost ? unsavedPost.post.post.message.type : null
    }
    return null
}

export function unsavedMessage(boardId: string): UnsavedPostData | null {
    if (store.state.AdminTemplate.user) {
        return store.state.AdminTemplate.user.data[boardId]?.unsavedPostData
    }
    return null
}
